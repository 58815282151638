import AnalyticsConstants from '@constants/analytics/index.js';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { getPlatform } from '@utils/getPlatform';
import { useCallback } from 'react';
import VendorAnalytics from '../../../decorators/vendorAnalytics';

export type UgcStorefrontAnalyticsAction =
	| typeof AnalyticsConstants.SEE_ALL_CTA
	| typeof AnalyticsConstants.SEE_ALL_IMAGES
	| typeof AnalyticsConstants.SEE_ALL_TILE
	| typeof AnalyticsConstants.PHOTO_VIEWED
	| typeof AnalyticsConstants.NAV_LEFT
	| typeof AnalyticsConstants.NAV_RIGHT
	| typeof AnalyticsConstants.NAV_TO_PHOTO
	| typeof AnalyticsConstants.DISMISS;

export type UgcSourceContent =
	| typeof AnalyticsConstants.REVIEW_GALLERY_FILMSTRIP
	| typeof AnalyticsConstants.REVIEW_OVERVIEW
	| typeof AnalyticsConstants.REVIEW_GALLERY
	| typeof AnalyticsConstants.PAID_STOREFRONT
	| typeof AnalyticsConstants.UNPAID_STOREFRONT
	| typeof AnalyticsConstants.portfolioType.REVIEWS;

export const useUgcStorefrontAnalytics = () => {
	const { track } = useAnalyticsContext();
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);
	const anonymousId = useAppSelector((state) => state.settings.anonymousId);

	return useCallback(
		(
			action: UgcStorefrontAnalyticsAction,
			sourceContent: UgcSourceContent,
			photoNumber?: number,
		) => {
			const sourcePage = vendor.isPaid
				? AnalyticsConstants.PAID_STOREFRONT
				: AnalyticsConstants.UNPAID_STOREFRONT;
			const vendorAnalytics = new VendorAnalytics(vendor);

			track({
				event: AnalyticsConstants.REVIEW_INTERACTION,
				properties: {
					...vendorAnalytics.baseEventProps(),
					action,
					anonymousId,
					platform: getPlatform(),
					sourcePage,
					sourceContent,
					...(photoNumber !== undefined && { photoNumber }),
				},
			});
		},
		[track, vendor],
	);
};
