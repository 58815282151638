import {
	getCardTitle,
	getLinkUrl,
} from '@components/shared/SmallCategoryCard/utils';
import categoryMediaMappings from '@utils/categoryMediaMappings';
import { Img, Picture } from '@xo-union/tk-component-picture';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { useCallback } from 'react';
import type { CategoryCode } from 'types/Category';
import Styles from './styles.scss';
import PageType = Page.PageType;
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import type { TopTenCategory } from '../../../../constants/categories';
import {
	CATEGORIES_LIST_VIEW,
	CLICK_THROUGH_TO_VENDOR_CATEGORY,
} from '../../../utils/tracking';

export interface SmallCategoryCardV2Props {
	category: TopTenCategory;
	pageType: PageType;
	sourceContent?: string;
	city: string;
	stateCode: string;
	fullWidth?: boolean;
}

const SMALL_CATEGORY_CARD_IMG_WIDTH = 140;
const SMALL_CATEGORY_CARD_IMG_HEIGHT = 100;
const SMALL_CATEGORY_FULL_WIDTH_CARD_IMG_WIDTH = 250;

export const SmallCategoryCardV2 = ({
	category,
	pageType,
	city,
	stateCode,
	sourceContent = CATEGORIES_LIST_VIEW,
	fullWidth = false,
}: SmallCategoryCardV2Props) => {
	const { track } = useAnalyticsContext();
	const isMobile = useAppSelector((state) => selectIsMobile(state));

	const handleClick = () => {
		track({
			event: CLICK_THROUGH_TO_VENDOR_CATEGORY,
			properties: {
				sourcePage: pageType,
				sourceContent,
				vendorCategoryCode: category.code,
			},
		});
	};

	const title = getCardTitle(pageType, category.name, city, stateCode);

	return (
		<a
			href={getLinkUrl(category.urlSlug, pageType, undefined, city, stateCode)}
			onClick={handleClick}
			className={Styles.smallCategoryCardContainer}
		>
			<Picture
				env="production"
				id={categoryMediaMappings[category.code as CategoryCode].mediaId}
			>
				<Img
					alt={title}
					elementProps={{
						'aria-hidden': true,
						loading: 'lazy',
					}}
					className={Styles.smallCategoryCardImage}
					height={SMALL_CATEGORY_CARD_IMG_HEIGHT}
					width={
						fullWidth && !isMobile
							? SMALL_CATEGORY_FULL_WIDTH_CARD_IMG_WIDTH
							: SMALL_CATEGORY_CARD_IMG_WIDTH
					}
					data-testid="small-category-card-img"
					smartCrop
				/>
			</Picture>
			<Body1 className={Styles.categoryCaption}>{title}</Body1>
		</a>
	);
};
