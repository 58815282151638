import { useAtomValue } from 'jotai';
import React from 'react';
import {
	areStarRatingsSavedAtom,
	currentStarRatingsAtom,
	previousStarRatingsAtom,
} from '../atoms';
import { StarPill } from './star-pill';
import Styles from './styles.scss';

const StarPills = () => {
	const currentStarRatings = useAtomValue(currentStarRatingsAtom);
	const previousStarRatings = useAtomValue(previousStarRatingsAtom);
	const areStarRatingsSaved = useAtomValue(areStarRatingsSavedAtom);

	return (
		<div className={Styles.container}>
			{areStarRatingsSaved &&
				currentStarRatings.length > 0 &&
				currentStarRatings
					.sort()
					.reverse()
					.map((text) => <StarPill key={text} text={text} />)}
			{!areStarRatingsSaved &&
				previousStarRatings.length > 0 &&
				previousStarRatings
					.sort()
					.reverse()
					.map((text) => <StarPill key={text} text={text} />)}
		</div>
	);
};

export { StarPills };
