import { reviewStarBucketsAtom } from '@components/reviews/atoms';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

interface SelectableOptions {
	hasReviews: boolean;
	starRating: string;
}

const useGetOptions = (): SelectableOptions[] => {
	const reviewBuckets = useAtomValue(reviewStarBucketsAtom);
	const options: SelectableOptions[] = useMemo(() => {
		return Object.entries(reviewBuckets).map(([key, value]) => {
			const starValue = key.split('stars')[1];
			const starRating = `${starValue} star${
				Number.parseInt(starValue, 10) > 1 ? 's' : ''
			}`;
			if (value === 0) {
				return {
					hasReviews: false,
					starRating,
				};
			}
			return {
				hasReviews: true,
				starRating,
			};
		});
	}, [reviewBuckets]);

	return options;
};

export { useGetOptions };
