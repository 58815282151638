import React, { type ReactNode, useEffect, useState } from 'react';

export const ClientOnly = ({ children }: { children: ReactNode }) => {
	const [hasMounted, setHasMounted] = useState(false);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	if (!hasMounted) {
		return null;
	}

	return <>{children}</>;
};
