import { useAppSelector } from '@redux/hooks';

/**
 * The Wedding Wire PDF URL is the new pdf url to use for pdf pricing.
 * The TKPro PDF URL is the fallback pdf url to use for pdf pricing.
 * If we have neither, we should not show the PDF component.
 *
 */

const usePdfUrl = () => {
	const pdfUrl = useAppSelector((state) => state.settings.pdfUrl);

	return pdfUrl || null;
};

export { usePdfUrl };
