import { useAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import { useCallback } from 'react';
import {
	areStarRatingsSavedAtom,
	currentStarRatingsAtom,
	previousStarRatingsAtom,
	searchSortFilterAtom,
	searchSortFilterReducer,
} from '../atoms';

const useStarReviews = (handleClose?: () => void) => {
	const [currentStarRatings, setCurrentStarRatings] = useAtom(
		currentStarRatingsAtom,
	);
	const [previousStarRatings, setPreviousStarRatings] = useAtom(
		previousStarRatingsAtom,
	);
	const [areStarRatingsSaved, setAreStarRatingsSaved] = useAtom(
		areStarRatingsSavedAtom,
	);
	const [, dispatch] = useReducerAtom(
		searchSortFilterAtom,
		searchSortFilterReducer,
	);

	const handleClearAll = useCallback(() => {
		setCurrentStarRatings([]);
		if (previousStarRatings.length === 0) {
			setAreStarRatingsSaved(true);
		} else {
			setAreStarRatingsSaved(false);
		}
	}, [previousStarRatings]);

	const handleSave = useCallback(() => {
		if (!areStarRatingsSaved) {
			setPreviousStarRatings(currentStarRatings);
			setAreStarRatingsSaved(true);
			const ratings = currentStarRatings.map((rating) =>
				parseInt(rating.split(' ')[0], 10),
			);
			dispatch({ type: 'star-rating', payload: ratings });
			handleClose?.();
		}
	}, [areStarRatingsSaved, dispatch, handleClose, currentStarRatings]);

	return { handleClearAll, handleSave };
};

export { useStarReviews };
