import { ClassesRecord, compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import {
	Checkbox,
	CheckboxClasses,
	MultiSelectGroup,
} from '@xo-union/tk-component-switches';
import classnames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import React, { FC } from 'react';
import {
	areStarRatingsSavedAtom,
	currentStarRatingsAtom,
	previousStarRatingsAtom,
} from '../../../atoms';
import { useGetOptions } from '../../../hooks/use-get-options';
import { useStarReviews } from '../../../hooks/use-star-reviews';
import Styles from './styles.scss';

export interface BodyProps {
	handleClose?: () => void;
	isMobile?: boolean;
}

const getCheckboxClasses = (hasReviews: boolean) => {
	return compose<CheckboxClasses, ClassesRecord<CheckboxClasses>>({
		container: classnames({
			[hasReviews ? Styles.container : Styles.containerDisabled]: true,
		}),
		'checkmark-border': classnames({ [Styles.checkmarkBorder]: !hasReviews }),
		label: classnames({ [Styles.label]: !hasReviews }),
		'label-text': classnames({ [Styles.labelText]: !hasReviews }),
	});
};

const Body: FC<BodyProps> = (props) => {
	const { handleClose, isMobile = true } = props;
	const [currentStarRatings, setCurrentStarRatings] = useAtom(
		currentStarRatingsAtom,
	);
	const previousStarRatings = useAtomValue(previousStarRatingsAtom);
	const [areStarRatingsSaved, setAreStarRatingsSaved] = useAtom(
		areStarRatingsSavedAtom,
	);
	const options = useGetOptions();
	const { handleClearAll, handleSave } = useStarReviews(handleClose);

	const handleChange = (values: string[]) => {
		setCurrentStarRatings(values);

		const hasPendingChanges = !(
			previousStarRatings.every((p) => values.includes(p)) &&
			previousStarRatings.length === values.length
		);
		if (hasPendingChanges) {
			setAreStarRatingsSaved(false);
		} else {
			setAreStarRatingsSaved(true);
		}
	};

	return (
		<div className={Styles.bodyContainer}>
			<MultiSelectGroup
				values={currentStarRatings}
				onValuesChange={handleChange}
			>
				{options.map(({ hasReviews, starRating }) => (
					<Checkbox
						disabled={!hasReviews}
						classes={getCheckboxClasses(hasReviews)}
						value={starRating}
						key={starRating}
					>
						{starRating}
					</Checkbox>
				))}
			</MultiSelectGroup>
			{!isMobile && (
				<div className={Styles.saveContainer}>
					<div className={Styles.buttonGroup}>
						<Button
							onClick={handleClearAll}
							className={Styles.clearButton}
							color="tertiary-alternative"
							size="md"
						>
							Clear
						</Button>
						<Button
							onClick={handleSave}
							disabled={areStarRatingsSaved}
							className={Styles.saveButton}
							color="tertiary-alternative"
							size="md"
						>
							Save
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export { Body };
