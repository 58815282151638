import { FilmstripCarousel } from '@components/filmstrip-carousel/FilmstripCarousel';
import { galleryAtom } from '@components/reviews/atoms';
import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { useAppSelector } from '@redux/hooks';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Editorial } from '@xo-union/tk-ui-typography';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useState, useCallback } from 'react';
import Styles from './ReviewPhotosFilmstrip.scss';

export const ReviewPhotosFilmstrip = () => {
	const gallery = useAtomValue(galleryAtom);
	const trackUgcStorefrontAnalytics = useUgcStorefrontAnalytics();
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);

	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const handleOpenUnifiedLightbox = useCallback(
		async (index: number) => {
			const reviewMediaId = gallery[index]?.sourceId;
			setOpenUnifiedLightbox({
				indexInAllMedia: index,
				filter: 'reviews',
				reviewMediaId,
			});
			setIsUnifiedLightboxModalOpen(true);
		},
		[gallery, setOpenUnifiedLightbox],
	);

	const onClick = useCallback(
		(index: number) => {
			handleOpenUnifiedLightbox(index);
		},
		[handleOpenUnifiedLightbox],
	);

	const onClickSeeAll = useCallback(() => {
		trackUgcStorefrontAnalytics(
			AnalyticsConstants.SEE_ALL_IMAGES,
			AnalyticsConstants.portfolioType.REVIEWS,
		);

		onClick(0);
	}, [trackUgcStorefrontAnalytics, onClick]);

	if (!gallery?.length) {
		return null;
	}

	return (
		<div className={Styles.filmstripContainer}>
			<div className={Styles.headerContainer}>
				<Editorial bold>Review photos</Editorial>
				<div className={Styles.seeAllContainer}>
					<DisplayButton
						type="button"
						color="primary"
						onClick={() => {
							onClickSeeAll();

							trackUnifiedLightbox(
								AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
								AnalyticsConstants.SEE_ALL_IMAGES,
								AnalyticsConstants.portfolioType.REVIEWS,
							);
						}}
					>
						See all
					</DisplayButton>
				</div>
			</div>
			<FilmstripCarousel galleryItems={gallery} onClick={onClick} />
			{isUnifiedLightboxModalOpen && (
				<UnifiedLightboxModal
					entryPoint="Reviews"
					isModalOpen={isUnifiedLightboxModalOpen}
					closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
				/>
			)}
		</div>
	);
};
