import { pvRec2point0AssignmentSelector } from '@redux/experiments/selectors/pvrec2point0';
import { connect } from 'react-redux';
import WidgetContainer from './components';
import algorithms from './constants/algorithms';

import {
	fetchBowVendors,
	fetchPvRecCrossCatVendors,
	fetchSimilarVendors,
} from './actions';

export function mapStateToProps(state) {
	const recommendedVendors = [];

	recommendedVendors[algorithms.SIMILAR] =
		state.widget.similarVendorsReducer.recommendedVendors;
	recommendedVendors[algorithms.BOW] =
		state.widget.bowVendorsReducer.recommendedVendors;
	recommendedVendors[algorithms.PVREC_CROSS_CAT] =
		state.widget.pvRecCrossCatVendorsReducer.recommendedVendors;

	return {
		member: state.membership.member,
		vendor: state.vendor.vendor,
		viewport: state.viewport,
		recommendedVendors,
		pvRec2point0Assignment: pvRec2point0AssignmentSelector(state),
	};
}

export const mapDispatchToProps = {
	fetchSimilarVendors,
	fetchBowVendors,
	fetchPvRecCrossCatVendors,
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);
