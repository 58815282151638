import { Highlight } from '@components/highlight/highlight';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import { useAtomValue } from 'jotai';
import React, { useCallback } from 'react';
import { Link } from 'react-scroll';
import {
	guestCapacityAtom,
	guestCapacityMaxAtom,
	startingCostAtom,
} from '../../../jotai/storefront/pricing';
import Styles from './price-and-guests-highlights.styles.scss';

const PriceAndGuestsHighlights = () => {
	const impressionRef = useTrackStorefrontImpression(
		'Venue Pricing Highlights',
	);
	const guestCapacity = useAtomValue(guestCapacityAtom);
	const guestCapacityMax = useAtomValue(guestCapacityMaxAtom);
	const priceHeaderText = useAtomValue(startingCostAtom);
	const trackSeeDetails = useTrackStorefrontInteraction('content highlights');
	const handleSeeDetails = useCallback(() => {
		trackSeeDetails('See details');
	}, [trackSeeDetails]);
	const isLiteStorefront = useLiteStorefront();

	if (!guestCapacity && !priceHeaderText) return null;

	const guestCapacityText =
		guestCapacityMax?.trim() === '300+'
			? `This venue can host ${guestCapacityMax} guests`
			: `This venue can host up to ${guestCapacityMax} guests`;

	return (
		<div className={Styles.highlights} ref={impressionRef}>
			{priceHeaderText && (
				<>
					<meta
						itemProp="priceRange"
						content={`${priceHeaderText}+`}
						data-testid="meta-price-range"
					/>
					<Highlight
						iconName="budgeter"
						highlightTitle={`${priceHeaderText} starting price`}
						highlightText={
							!isLiteStorefront ? (
								<Link
									href={'#navPricing'}
									onClick={handleSeeDetails}
									to="navPricing"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									See details
								</Link>
							) : null
						}
					/>
				</>
			)}

			{guestCapacity && (
				<Highlight
					iconName="guests"
					highlightTitle={`${guestCapacity} guest capacity`}
					highlightText={guestCapacityText}
				/>
			)}
		</div>
	);
};

export { PriceAndGuestsHighlights };
