import {
	type BowVendorSearchArgs,
	bowVendorSearch,
} from '@api/bow-vendor-search';
import {
	fetchBowVendorsAction,
	fetchBowVendorsErrorAction,
	fetchBowVendorsSuccessAction,
} from '@redux/widget/actions';
import type { ThunkResult } from '@typings/redux';
import type Vendor from '../../../../decorators/vendor';
import constants from '../../constants';

const fetchBowVendors = (vendor: Vendor): ThunkResult<Promise<void>> => {
	return async (dispatch, getState) => {
		try {
			const {
				category: { id },
				searchPageLocation: { city, stateCode: state },
			} = getState();
			const location =
				city && state
					? { city, state }
					: { city: vendor.address.city, state: vendor.address.state };

			const options: BowVendorSearchArgs = {
				...location,
				categoryGuid: id,
				limit: constants.DEFAULT_VENDOR_LIMIT_FETCH,
			};

			if (options.city && options.state) {
				dispatch(fetchBowVendorsAction());
				const vendors = await bowVendorSearch(options);

				dispatch(fetchBowVendorsSuccessAction(vendors));
			}
		} catch (error) {
			dispatch(fetchBowVendorsErrorAction(error));
			throw error;
		}
	};
};

export default fetchBowVendors;
