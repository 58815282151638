import { ViewReviews } from '@typings/reviews';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import {
	galleryAtom,
	ratingAtom,
	reviewStarBucketsAtom,
	ssrReviewsAtom,
	totalCountAtom,
} from '../atoms';
import {
	isViewMoreAtom,
	pageAtom,
	reviewsAtom,
	searchSortFilterAppliedAtom,
	searchSortFilterAtom,
} from '../components/search-sort-filter/atoms';

export const useViewReviews = (): ViewReviews => {
	const searchSortFilter = useAtomValue(searchSortFilterAtom);
	const searchSortFilterApplied = useAtomValue(searchSortFilterAppliedAtom);
	const isViewMore = useAtomValue(isViewMoreAtom);
	const totalCount = useAtomValue(totalCountAtom);
	const buckets = useAtomValue(reviewStarBucketsAtom);
	const gallery = useAtomValue(galleryAtom);
	const rating = useAtomValue(ratingAtom);
	const page = useAtomValue(pageAtom);
	const ssrReviews = useAtomValue(ssrReviewsAtom);
	const [reviewsToDisplay, setReviewsToDisplay] = useState<ViewReviews>({
		collection: ssrReviews,
		totalVendorReviewsCount: totalCount,
		isFetchingMore: false,
		isFetching: false,
		totalCount,
		buckets,
		rating,
		gallery,
	});
	const reviews = useAtomValue(reviewsAtom);
	useEffect(() => {
		if (searchSortFilter.lastAction === 'ssr-hydration') {
			setReviewsToDisplay({
				collection: ssrReviews,
				isFetching: false,
				isFetchingMore: false,
				totalCount,
				buckets,
				gallery,
				rating,
				totalVendorReviewsCount: totalCount,
			});
			return;
		}
		if (reviews.state === 'hasData') {
			setReviewsToDisplay((prev) => {
				const collection =
					page > 1
						? [...prev.collection, ...reviews.data.data]
						: reviews.data.data;
				return {
					collection,
					isFetching: false,
					totalCount: reviews.data.totalCount,
					buckets,
					gallery,
					rating,
					totalVendorReviewsCount: totalCount,
					isFetchingMore: false,
				};
			});
		} else if (reviews.state === 'loading') {
			setReviewsToDisplay((prev) => ({
				...prev,
				isFetching: !isViewMore,
				isFetchingMore: isViewMore,
			}));
		}
		if (reviews.state === 'hasError') {
			setReviewsToDisplay({
				collection: [],
				isFetching: false,
				isFetchingMore: false,
				totalCount: 0,
				buckets,
				gallery,
				rating,
				totalVendorReviewsCount: totalCount,
			});
		}
	}, [reviews.state, searchSortFilterApplied, buckets, rating]);

	return reviewsToDisplay;
};
