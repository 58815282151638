import { Cta } from '@components/pricing/pricing-details/Cta';
import { DisclaimerText } from '@components/pricing/pricing-details/DisclaimerText';
import { Faq } from '@components/pricing/pricing-details/Faq';
import { useStorefrontCouplesUsuallySpend } from '@feature-flags/hooks/Storefront/useStorefrontCouplesUsuallySpend';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { Editorial } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React from 'react';
import {
	formattedPricingDetailsAtom,
	hasPricingDetailsAtom,
} from '../../../jotai/storefront/pricing';
import { EmptyStateSearchLink } from '../../../pages/Storefront/components/EmptyStateSearchLink/EmptyStateSearchLink';
import Styles from './PricingDetails.scss';

export const PricingDetails = () => {
	const pricingDetails = useAtomValue(formattedPricingDetailsAtom);
	const hasPricingDetails = useAtomValue(hasPricingDetailsAtom);
	const cusEnabled = useStorefrontCouplesUsuallySpend();
	const isEmptyStates = useStorefrontEmptyStates();

	if (isEmptyStates && !hasPricingDetails) {
		return (
			<EmptyStateSearchLink
				header="No pricing details yet"
				text="We’re here to help! Find other vendors that suit your budget."
				linkText="Continue browsing vendors"
				storefrontSection="Venue Pricing FAQs"
				inline={false}
			/>
		);
	}

	return (
		<>
			<div className={Styles.pricingDetails}>
				<Editorial bold>Starting prices</Editorial>
				<div className={Styles.faqs}>
					<Faq
						text="Reception"
						iconName="category-rec"
						value={pricingDetails.reception}
					/>
					<Faq
						text="Ceremony"
						iconName="category-cer"
						value={pricingDetails.ceremony}
					/>
					<Faq
						text="Bar services"
						iconName="category-bar"
						value={pricingDetails.barService}
					/>
					<Faq
						text="Catering"
						iconName="category-cat"
						value="Contact for price"
					/>
				</div>
				<DisclaimerText />
				<Cta />
			</div>
		</>
	);
};
