import { TeamMemberModal } from '@components/team-member-modal/team-member-modal';
import React, { useState } from 'react';
import { TeamMemberImage } from './components/team-member-image/team-member-image';
import {
	type TeamMemberBio,
	TeamMemberInfo,
} from './components/team-member-info/team-member-info';
import Style from './team-member-card.scss';

type TeamMemberCardProps = {
	memberBio: TeamMemberBio;
	className?: string;
	isReduced?: boolean;
};

export const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
	memberBio,
	className = '',
	isReduced = false,
}) => {
	const [openModal, setOpenModal] = useState(false);

	const openMemberCardModal = () => {
		isReduced && setOpenModal(true);
	};

	const closeModalHandler = () => {
		setOpenModal(false);
	};

	const handleEnter = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			openMemberCardModal();
		}
	};

	return (
		<>
			<div
				className={`${Style.container} ${
					isReduced ? Style.reduced : Style.complete
				} ${className}`}
				onClick={openMemberCardModal}
				onKeyUp={handleEnter}
				role="button"
				tabIndex={0}
			>
				<TeamMemberImage
					className={Style.image}
					imageUrl={memberBio.photo?.url}
				/>
				<TeamMemberInfo memberBio={memberBio} isReduced={isReduced} />
			</div>
			{openModal && (
				<TeamMemberModal memberBio={memberBio} onClose={closeModalHandler} />
			)}
		</>
	);
};
