import Icon from '@xo-union/tk-component-icons';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import { currentStarRatingsAtom } from '../../../atoms';
import { useStarReviews } from '../../../hooks/use-star-reviews';
import Styles from './styles.scss';

interface HeaderProps {
	handleClose: () => void;
}

const Header: FC<HeaderProps> = (props) => {
	const { handleClose } = props;
	const currentStarRatings = useAtomValue(currentStarRatingsAtom);
	const { handleClearAll } = useStarReviews();

	return (
		<div className={Styles.header}>
			{currentStarRatings.length > 0 && (
				<button
					className={Styles.clearAllBtn}
					type="button"
					onClick={handleClearAll}
				>
					Clear all
				</button>
			)}
			<h4 className={Styles.title}>Ratings</h4>
			<button
				aria-label="Close modal"
				name="close-btn"
				className={Styles.closeBtn}
				onClick={handleClose}
				type="button"
			>
				<Icon name="close" size="md" />
			</button>
		</div>
	);
};

export { Header };
