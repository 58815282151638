import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useBaseStorefrontAnalytics } from '@hooks/useBaseStorefrontAnalytics';
import { useAtomValue, useSetAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import { useEffect, useMemo, useState } from 'react';
import {
	reviewsAtom,
	searchSortFilterAtom,
	searchSortFilterReducer,
	searchTextDisplayAtom,
} from '../atoms';

export const useSearchSortFilterReviews = () => {
	const [state, dispatch] = useReducerAtom(
		searchSortFilterAtom,
		searchSortFilterReducer,
	);
	const setSearchTextDisplay = useSetAtom(searchTextDisplayAtom);
	const [reviewCount, setReviewCount] = useState(0);
	const loadableReview = useAtomValue(reviewsAtom);

	const baseStorefrontAnalytics = useBaseStorefrontAnalytics();
	const { track } = useAnalyticsContext();

	const actionsToTrackAfterFetch = ['search', 'star-rating', 'sort'];

	const sortTypeMap = {
		'selectiontype:asc|createddate:desc': 'Top Reviews',
		'createddate:desc': 'Newest First',
		'createddate:asc': 'Oldest First',
		'rating:desc': 'Highest Rated',
		'rating:asc': 'Lowest Rated',
	};

	const actionMap = {
		search: 'initiate search',
		'star-rating': 'filter reviews',
		sort: 'sort reviews',
	};

	const starTextMap = {
		1: 'One star',
		2: 'Two stars',
		3: 'Three stars',
		4: 'Four stars',
		5: 'Five stars',
	};

	const trackSearchSortFilterAfterFetch = (numberOfResults: number) => {
		const {
			lastAction,
			queryParams: { content, sort, stars },
		} = state;
		if (!lastAction || !actionsToTrackAfterFetch.includes(lastAction)) return;
		const analyticsProperties = {
			...baseStorefrontAnalytics,
			numberOfResults,
			sortType: sortTypeMap[sort],
			...(content && { searchString: content }),
			...(stars &&
				stars.length > 0 && {
					filterType: 'Rating',
					selection: stars
						.map((star) => starTextMap[star as keyof typeof starTextMap])
						.join(', '),
				}),
			action: actionMap[lastAction as keyof typeof actionMap],
		};
		track({
			event: 'Review Interaction',
			properties: analyticsProperties,
		});
	};

	const shouldShowText = useMemo(() => {
		if (state.showText === 'hide-if-loading') {
			return loadableReview.state !== 'loading';
		}
		return state.showText === 'show';
	}, [state.showText, loadableReview.state]);

	const displayText = useMemo(() => {
		if (
			loadableReview.state === 'hasError' &&
			(loadableReview.error as Error)?.name !== 'AbortError'
		) {
			return 'Something went wrong. Please try again.';
		}
		return '';
	}, [loadableReview.state, reviewCount]);

	useEffect(() => {
		if (loadableReview.state === 'hasData') {
			setReviewCount(loadableReview.data.totalCount);
			trackSearchSortFilterAfterFetch(loadableReview.data.totalCount);
		}
		if (
			loadableReview.state === 'hasError' &&
			(loadableReview.error as Error)?.name !== 'AbortError'
		) {
			setReviewCount(0);
		}
	}, [loadableReview.state]);

	const setSortSelection = (sort: string) =>
		dispatch({ type: 'sort', payload: sort });
	const setSearchText = (content: string | undefined) =>
		dispatch({ type: 'search', payload: content });

	const clearSearchText = () => {
		setSearchTextDisplay('');
		setSearchText(undefined);
	};

	const clearAll = () => {
		setSearchTextDisplay('');
		dispatch({ type: 'reset' });
	};

	return {
		setSortSelection,
		clearSearchText,
		setSearchText,
		clearAll,
		reviewCount,
		shouldShowText,
		displayText,
	};
};
