import { useCallback, useMemo, useState } from 'react';
import { PLACEHOLDER_CONSTANTS } from '../../../pages/Storefront/components/Media/constants';

export const usePictureWrapper = (id?: string) => {
	const [isErrored, setIsErrored] = useState(false);
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const onLoad = useCallback(() => {
		setIsImageLoaded(true);
	}, []);

	const onError = useCallback(() => {
		setIsErrored(true);
	}, []);

	const idToUse = useMemo(() => {
		return isErrored ? PLACEHOLDER_CONSTANTS.id : id;
	}, [id, isErrored]);

	const imageComplete = useCallback(
		(node: HTMLImageElement | null) => {
			if (node?.complete) {
				onLoad();
			}
		},
		[onLoad],
	);

	return { isErrored, isImageLoaded, onLoad, onError, idToUse, imageComplete };
};
