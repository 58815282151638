import { Img } from '@xo-union/tk-component-picture';
import { Caption } from '@xo-union/tk-ui-typography';
import React from 'react';
import { NO_IMAGE_PATH } from '../../../../pages/Storefront/components/Media/constants';
import Style from './team-member-image.scss';

type TeamMemberImageProps = {
	imageUrl?: string;
	className?: string;
};

export const TeamMemberImage: React.FC<TeamMemberImageProps> = ({
	imageUrl,
	className = '',
}) => {
	return imageUrl ? (
		<Img className={className} src={imageUrl} alt="Vendor" smartCrop />
	) : (
		<div className={`${className} ${Style.noImageContainer}`}>
			<div className={Style.noImage}>
				<Img src={NO_IMAGE_PATH} alt="no photo yet" smartCrop />
				<Caption size="sm">No photo yet</Caption>
			</div>
		</div>
	);
};
