import { useAtomValue, useSetAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import { useCallback } from 'react';
import {
	areStarRatingsSavedAtom,
	currentStarRatingsAtom,
	previousStarRatingsAtom,
	reviewsAtom,
	searchSortFilterAtom,
	searchSortFilterReducer,
} from '../components/search-sort-filter/atoms';

interface UseReviewSummaryFilterReturnValues {
	filterByReviewSummary: () => void;
}

const useReviewSummaryFilter = (
	text: '5 Star' | '4 Star' | '3 Star' | '2 Star' | '1 Star',
): UseReviewSummaryFilterReturnValues => {
	useAtomValue(reviewsAtom);
	const [, dispatch] = useReducerAtom(
		searchSortFilterAtom,
		searchSortFilterReducer,
	);
	const setCurrentStarRatings = useSetAtom(currentStarRatingsAtom);
	const setPreviousStarRatings = useSetAtom(previousStarRatingsAtom);
	const setAreStarRatingsSaved = useSetAtom(areStarRatingsSavedAtom);

	const filterByReviewSummary = useCallback(() => {
		const [rating] = text.split(' ');
		const ratingWithStar = `${rating} star${
			parseInt(rating, 10) > 1 ? 's' : ''
		}`;
		setCurrentStarRatings([ratingWithStar]);
		setPreviousStarRatings([ratingWithStar]);
		setAreStarRatingsSaved(true);
		dispatch({ type: 'star-rating', payload: [parseInt(rating, 10)] });
	}, [dispatch, text]);

	return { filterByReviewSummary };
};

export { useReviewSummaryFilter };
