import type {
	PhotoPictureWrapperProps,
	Source,
} from '@components/photo-picture-wrapper/types';
import type { SourceProps } from '@xo-union/tk-component-picture';

export type BuildSourceProps = {
	source?: Source;
	isErrored: boolean;
	scaling?: number;
} & Pick<PhotoPictureWrapperProps, 'smartCrop' | 'fit' | 'src' | 'id'>;

export const buildSourceProps = ({
	source = {},
	scaling = 1,
	smartCrop,
	fit,
	src,
	id,
	isErrored,
}: BuildSourceProps): SourceProps => {
	const height = source?.height
		? Math.round(source.height * scaling)
		: undefined;
	const width = source?.width ? Math.round(source.width * scaling) : undefined;
	const scaledSource: Source = {
		...(source || {}),
		height,
		width,
	};

	return {
		...scaledSource,
		smartCrop: source?.smartCrop ?? smartCrop,
		fit: source?.fit ?? fit,
		src: id || isErrored ? undefined : (source?.src ?? src),
	};
};
