import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { pricingDetailsAtom } from '../../../jotai/storefront/pricing';
import Styles from './PricingDetails.scss';

const baseText =
	"Starting prices don't include service fees, taxes, gratuity, and rental fees. Guest count and seasonality may also affect prices.";
export const DisclaimerText = () => {
	const { months } = useAtomValue(pricingDetailsAtom);
	const text = useMemo(() => {
		if (!months) {
			return baseText;
		}

		return `${baseText} Peak season for this venue is ${months}.`;
	}, [months]);

	return (
		<div className={Styles.disclaimer}>
			<div>
				<Icon name="info" size="sm" />
			</div>
			<div>
				<Body2>{text}</Body2>
			</div>
		</div>
	);
};
