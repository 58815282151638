import Modal from '@xo-union/tk-component-modals';
import React from 'react';
import { TeamMemberImage } from '../team-member-card/components/team-member-image/team-member-image';
import {
	type TeamMemberBio,
	TeamMemberInfo,
} from '../team-member-card/components/team-member-info/team-member-info';
import Style from './team-member-modal.scss';

type TeamMemberModalProps = {
	memberBio: TeamMemberBio;
	className?: string;
	onClose: () => void;
};

export const TeamMemberModal: React.FC<TeamMemberModalProps> = ({
	memberBio,
	className = '',
	onClose,
}) => {
	const closeModalHandler = () => {
		onClose();
	};

	return (
		<Modal size="lg" onClose={closeModalHandler}>
			<div className={`${Style.container} ${Style.complete} ${className}`}>
				<TeamMemberImage
					className={Style.image}
					imageUrl={memberBio.photo?.url}
				/>
				<TeamMemberInfo
					className={Style.modalInfoContainer}
					memberBio={memberBio}
					showReadMore={false}
				/>
			</div>
		</Modal>
	);
};
