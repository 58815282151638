import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import { useScreenDetails } from '@hooks/useScreenDetails';
import { ModalPortal } from '@xo-union/ui-accessibility';
import React, { type FC } from 'react';
import { Body } from './body';
import { Footer } from './footer';
import { Header } from './header';
import Styles from './styles.scss';

export interface RatingsModalProps {
	isModalOpen: boolean;
	handleClose: () => void;
}

const RatingsModal: FC<RatingsModalProps> = (props) => {
	const { isModalOpen, handleClose } = props;
	const [, , windowHeight] = useScreenDetails();

	const asideStyle = { '--window-height': `${windowHeight}px` };

	return (
		<ModalPortal>
			<aside style={asideStyle} className={Styles.modalWrapper}>
				<NavigableModal
					hash="ratings-modal"
					isModalOpen={isModalOpen}
					closeCallback={handleClose}
					type={MODAL_TYPES.CONTAINER}
				>
					<Header handleClose={handleClose} />
					<Body />
					<Footer handleClose={handleClose} />
				</NavigableModal>
			</aside>
		</ModalPortal>
	);
};

export { RatingsModal };
